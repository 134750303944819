<template>
	<div class="bottom">
		<div class="left">
			<div>© STARSHIP PROJECT</div>
		</div>
		<div class="rift">
			<a href="https://speakerdeck.com/starship1016/starship-deck" target="_blank" rel="noopener noreferrer">
				<img src="/icons/deck.png" alt="" style="height: 20px" />
			</a>
			<a href="https://x.com/Starshipbnb" target="_blank" rel="noopener noreferrer">
				<img src="/icons/x.png" style="height: 20px" alt="" />
			</a>
			<a href="https://t.me/starshipbnb" target="_blank" rel="noopener noreferrer">
				<img src="/icons/tg.png" alt="" style="height: 20px" />
			</a>

			<!-- <el-avatar :size="40" src="/icons/logo.png"></el-avatar> -->
		</div>
	</div>
</template>

<style lang="scss">
	.bottom {
		height: 52px;
		background: rgb(43, 45, 56);
		padding: 16px;
		font-size: 12px;
		color: rgba(222, 226, 230, 0.75);
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;

		.rift {
			font-size: 0px !important;
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			gap: 32px;
		}
	}
</style>
