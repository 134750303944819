export default {
	message: {
		mainx: 'Starship - Mars Project Wealth Journey:',
		token: 'Token Name: stas',
		totalamount: 'Total Supply: 100 Billion',
		trade: 'Transaction Fee Rate: Buy/Sell 2.5%',
		destroy: 'Destruction Ratio: Buy/Sell 0.5%',
		Welfare: 'Planet welfare: 10% for invitations, 5% for invitations',
		invite: 'Copy Invitation Link',
		starship: '2000U [Starship]',
		dividend: 'Enjoy 5% Dividend from Transaction Fees',
		Delivery: 'Deliver 2000 ULP',
		income: 'Game Earnings Weighted 50% Dividend',
		ecology: 'Rights to Vote on Ecological Development and Priority Participation',
		Exclusive: 'Starship Exclusive Club',
		fuel: '200U [Fuel]',
		Distributefuel: 'Distribute 200 ULP',
		Incomecollection: 'Claim Earnings',
		Incomeavailable: 'Available Earnings: ',
		Receivedincome: 'Claimed Earnings: ',
		Receivebenefits: 'Claim Benefits',
		Invitationrewards: 'Invitation Rewards',
		Rewardsavailable: 'Available Rewards:',
		Getreferralrewards: 'Claim Referral Rewards',
		RedeemLP: 'Redeem LP',
		warn: 'Redeeming may result in the loss of certain rights, please proceed with caution',
		buystarship:'Buy Starship',
		Buyfuel:'Buy Fuel',
	},
}
