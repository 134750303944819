<template>
	<div class="strsmain">
		<div class="left">
			<div class="bannersd">
				<div :class="['banner', { headerAnimation: isAnimating }]">{{ randomAddress }}</div>
				<div class="banners">{{ SAlE }}</div>
			</div>

			<div class="admininfo">
				<div class="adminimg">
					<el-image style="width: 160px" src="/icons/xj.jpg" fit="contain" />
					<div>
						<span>Mars Project</span>
					</div>
				</div>

				<div class="textinfo">
					<span style="color: rgb(49, 210, 242); font-size: 16px; font-weight: bold">{{ $t("message.mainx") }}</span>
					<span>As the Starship progresses, Musk and SpaceX are preparing to launch their Mars colonization plan. Musk plans to leverage the influence of meme coins once again and intends to launch the second meme coin, $STAS, to Mars.</span>
					<!-- <span>{{ $t("message.totalamount") }}</span>
					<span>{{ $t("message.trade") }}</span>
					<span>{{ $t("message.destroy") }}</span> -->
					<div style="display: flex; flex-direction: row; align-items: center; gap: 16px; color: rgb(27, 178, 218); flex-wrap: wrap">
						<a href="https://x.com/Starshipbnb" target="_blank" rel="noopener noreferrer">[Twitter]</a>
						<a href="https://t.me/starshipbnb" target="_blank" rel="noopener noreferrer">[telegram]</a>
						<a href="https://speakerdeck.com/starship1016/starship-deck" target="_blank" rel="noopener noreferrer">[speakerdeck]</a>
					</div>
				</div>
			</div>
			<div class="Certification invite" style="color: rgb(49, 210, 242); font-size: 13px">
				<span>{{ $t("message.Welfare") }}</span
				><el-button size="small" type="danger" @click="Invitationlink">{{ $t("message.invite") }}</el-button>
			</div>
			<div class="Certification" style="display: flex; flex-direction: row; align-items: center; gap: 8px">
				<el-image style="height: 20px; width: 20px" src="/icons/sj.png" fit="contain"></el-image>
				<span style="color: rgb(27, 178, 218); font-size: 12px">The contract has been audited by</span>
				<el-image style="width: 80px" src="/icons/ck.svg" fit="contain"></el-image>

				<!-- <el-button type="success" :loading="loading" @click="Buy(5)">{{ $t("message.buystarship") }}</el-button> -->
			</div>
			<div class="Certification invite" style="color: rgb(254, 111, 254); font-size: 16px; font-weight: bold">
				<span>Starship Launch Rules</span>
				<!-- <el-button type="success" :loading="loading" @click="Buy(5)">{{ $t("message.buystarship") }}</el-button> -->
			</div>
			<div class="textinfo">
				<span>Starship: 2000U</span>
				<span>Starship Benefits:</span>
				<span>1. Transaction fee weighted at 5%;</span>
				<span>2. Gift of 1000U LP;</span>
				<span>3. 50% weighted dividends from game earnings;</span>
				<span>4. Rights for ecological development voting and priority participation;</span>
				<span>5. Exclusive Starship club.</span>

				<span>Fuel: 200U</span>
				<span>Fuel Benefits:</span>
				<span>1. Distribution of 200 LP;</span>
				<span>2. Transaction fee weighted at 5%.</span>

				<span>Community Benefits: Direct referrals 10%, indirect referrals 5%.</span>
				<!-- <span>{{ $t("message.dividend") }}</span>
				<span>{{ $t("message.Delivery") }}</span>
				<span>{{ $t("message.income") }}</span>
				<span>{{ $t("message.ecology") }}</span>
				<span>{{ $t("message.Exclusive") }}</span> -->
			</div>
		</div>
		<div class="rift">
			<div class="letfbox letftnoxbor">
				<div v-if="participate || !isswitch" class="mask"></div>
				<div class="buttonlist">
					<el-button :type="warning" @click="buttontoggle(1)">fuel</el-button>
					<el-button :type="danger" @click="buttontoggle(5)">starship</el-button>
					<!-- <el-button type="primary" :loading="loading" @click="Buy(1)">购买燃油</el-button>
					<el-button type="success" :loading="loading" @click="Buy(5)">购买星舰</el-button> -->
				</div>
				<div class="tagtop">
					<el-tag type="info" effect="dark">Select fuel</el-tag>
					<el-tag type="info" effect="dark">Choose starship</el-tag>
				</div>
				<el-input disabled v-model="usdtvalue" style="max-width: 600px" placeholder="Please input">
					<template #append>USDT</template>
				</el-input>
				<div class="tagbottom">
					<el-tag type="info" effect="dark">A user can only make one purchase.</el-tag>
				</div>

				<el-button :loading="loading" :type="info" @click="Buy(buyvalue)">{{ buytext }}</el-button>
			</div>

			<div v-if="participate" class="letfbox">
				<span style="color: rgb(254, 111, 254); font-size: 16px; font-weight: bold">{{ $t("message.Incomecollection") }}</span>
				<div style="display: flex; flex-direction: row; align-items: center; gap: 8px">
					<!-- 这里放份额 -->
					<span>Hold interest:</span>

					<el-tag type="danger" effect="dark">{{ shares > 1 ? "starship" : "fuel" }}</el-tag>
					<!-- <span style="color: rgb(254, 111, 254)">{{ shares > 1 ? "starship" : "fuel" }}</span> -->
				</div>
				<div style="display: flex; flex-direction: row; align-items: center; gap: 8px">
					<span>{{ $t("message.Incomeavailable") }}</span>
					<span style="color: rgb(254, 111, 254)">{{ (totalClaimable / 1).toFixed(3) }}</span>
					<span style="color: rgb(254, 111, 254)">STAS</span>
				</div>
				<div style="display: flex; flex-direction: row; align-items: center; gap: 8px">
					<span>{{ $t("message.Receivedincome") }}</span>
					<span style="color: rgb(254, 111, 254)">{{ (claimedTimestamps / 1).toFixed(3) }}</span>
					<span style="color: rgb(254, 111, 254)">STAS</span>
				</div>

				<el-button :loading="loadingclaim" @click="claim()">{{ $t("message.Receivebenefits") }}</el-button>
				<!-- <el-button type="primary" :loading="loadingclaim" @click="claim()">{{ $t("message.Receivebenefits") }}</el-button> -->
			</div>
			<div class="letfbox">
				<span style="color: rgb(254, 111, 254); font-size: 16px; font-weight: bold">{{ $t("message.Invitationrewards") }}</span>
				<div style="display: flex; flex-direction: row; align-items: center; gap: 8px">
					<span>Starship:</span>
					<span style="color: rgb(254, 111, 254)">{{ zstarship }}</span>
				</div>
				<div style="display: flex; flex-direction: row; align-items: center; gap: 8px">
					<span>Fuel:</span>
					<span style="color: rgb(254, 111, 254)">{{ zfull }}</span>
				</div>
				<div style="display: flex; flex-direction: row; align-items: center; gap: 8px">
					<span>{{ $t("message.Rewardsavailable") }}</span>
					<span style="color: rgb(254, 111, 254)">{{ (UserEarnings / 1).toFixed(3) }}</span>
					<span style="color: rgb(254, 111, 254)">USDT</span>
				</div>

				<el-button type="primary" :loading="loadingwithdrawEarnings" @click="withdrawEarnings()">{{ $t("message.Getreferralrewards") }}</el-button>
			</div>
			<div v-if="!isswitch && participate && shares > 0" class="letfbox">
				<span style="color: rgb(254, 111, 254); font-size: 16px; font-weight: bold">{{ $t("message.RedeemLP") }}</span>
				<span>{{ $t("message.warn") }}</span>
				<el-button type="primary" :loading="loadingredemption" @click="redemption()">{{ $t("message.RedeemLP") }}</el-button>
			</div>
			<div v-if="isswitch" class="letfbox">
				<span style="color: rgb(254, 111, 254); font-size: 16px; font-weight: bold">recent purchases</span>
				<div v-for="(item, index) in buylist" :key="index" class="tagtop">
					<span>{{ item.user }}</span>
					<span>{{ item.combo }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, getCurrentInstance, onMounted, watch, onBeforeUnmount } from "vue";
	// import { defineComponent } from "@vue/composition-api";
	import useClipboard from "vue-clipboard3";
	import { useRoute } from "vue-router";
	import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers/vue";
	import { getprovider } from "@/api/wallet";
	import { ethers } from "ethers";
	import axios from "axios";
	const route = useRoute();
	const { proxy } = getCurrentInstance();
	const { open, close } = useWeb3Modal();
	const { address, chainId, isConnected } = useWeb3ModalAccount();
	// const useraddress = route.params.address ? route.params.address : "0x694c1f1C0A30814Db16719c46F147683A52fbDF6";
	const USDT = ref("");
	const SAlE = ref("");
	const pool = ref("");
	const loading = ref(false);
	const participate = ref(false);
	const loadingclaim = ref(false);
	const loadingwithdrawEarnings = ref(false);
	const loadingredemption = ref(false);
	const totalClaimable = ref(0);
	const claimedTimestamps = ref(0);
	const UserEarnings = ref(0);
	const shares = ref(0);
	const zstarship = ref(0);
	const zfull = ref(0);
	const examine = ref(false);

	// 验证以太坊地址的正则表达式
	const isValidEthereumAddress = (address) => {
		const regex = /^0x[a-fA-F0-9]{40}$/;
		return regex.test(address);
	};
	const useraddress = ref(isValidEthereumAddress(route.params.address) ? route.params.address : "0x0000000000000000000000000000000000000000");
	const walletProvider = ref("");
	const urlhash = ref("");
	const getjson = async () => {
		try {
			const response = await axios.get("/Setting.json");
			const config = response.data;
			USDT.value = config.USDT;
			SAlE.value = config.SAlE;
			pool.value = config.POOL;
			urlhash.value = config.switch ? "https://bscscan.com/tx/" : "https://testnet.bscscan.com/tx/";
			walletProvider.value = config.switch ? config.BSCRPC : config.TESTNETRPC;
		} catch (error) {
			console.log(error);
		}
	};

	const isswitch = ref(true);

	const getdata = async () => {
		try {
			examine.value = true;
			const provider = new ethers.JsonRpcProvider(walletProvider.value);

			// 是否开启了预售
			let abi = ["function isswitch()public view returns(bool)"];
			let contract = new ethers.Contract(SAlE.value, abi, provider);
			let numb = await contract.isswitch();

			isswitch.value = numb;

			if (isConnected.value) {
				// 调用函数
				// 判断用户  是否注册
				let abi = ["function getdataall() public view returns (bool, uint256, uint256, uint256)"];
				let contract = new ethers.Contract(SAlE.value, abi, await getprovider().getSigner());
				let dataall = await contract.getdataall();
				console.log(dataall);
				participate.value = dataall[0];
				UserEarnings.value = ethers.formatUnits(dataall[1]);
				// 2  和3  分别是星舰  和然后推荐的数量
				zstarship.value = dataall[2];
				zfull.value = dataall[3];

				abi = [
					{
						inputs: [
							{
								internalType: "address",
								name: "",
								type: "address",
							},
						],
						name: "users",
						outputs: [
							{
								internalType: "uint8",
								name: "shares",
								type: "uint8",
							},
							{
								internalType: "uint256",
								name: "claimedTimestamps",
								type: "uint256",
							},
							{
								internalType: "uint256",
								name: "claimedAmount",
								type: "uint256",
							},
						],
						stateMutability: "view",
						type: "function",
					},
				];
				contract = new ethers.Contract(pool.value, abi, await getprovider().getSigner());
				let numb = await contract.users(address.value);

				claimedTimestamps.value = ethers.formatUnits(numb[2].toString());

				shares.value = numb[0];

				// 获取已经领取的分红 还未开始 是不能获取的

				abi = ["function getclaim() public view returns (uint256)"];
				contract = new ethers.Contract(pool.value, abi, await getprovider().getSigner());
				numb = await contract.getclaim();

				totalClaimable.value = ethers.formatUnits(numb);
			}
			examine.value = false;
		} catch (error) {
			examine.value = false;
			console.log(error);
			// participate.value = false;
		}
	};

	const fetchRecentPurchases = async () => {
		// 假设您已经定义了一个方法来获取购买记录数量
		try {
			const provider = new ethers.JsonRpcProvider(walletProvider.value);

			const contractABI = [
				{
					inputs: [],
					name: "getRecentPurchases",
					outputs: [
						{
							components: [
								{
									internalType: "address",
									name: "user",
									type: "address",
								},
								{
									internalType: "uint8",
									name: "combo",
									type: "uint8",
								},
								{
									internalType: "uint256",
									name: "blockstamp",
									type: "uint256",
								},
							],
							internalType: "struct PrivatePlacement.Purchase[]",
							name: "",
							type: "tuple[]",
						},
					],
					stateMutability: "view",
					type: "function",
				},
			];

			// 创建合约实例
			let contract = new ethers.Contract(SAlE.value, contractABI, provider);

			// 获取最近购买记录

			const count = await contract.getRecentPurchases(); // 获取购买记录的数量

			return count;
		} catch (error) {
			return [];
		}
	};
	const buylist = ref([]);
	const fetchAllPurchases = async () => {
		try {
			const purchases = await fetchRecentPurchases();
			const provider = new ethers.JsonRpcProvider(walletProvider.value);
			for (const purchase of purchases) {
				const { user, combo, blockstamp } = purchase;
				buylist.value.push({ user: user.slice(0, 14) + "..." + user.slice(-8), combo: combo == 1 ? "200 USDT" : "2000 USDT" });
			}
		} catch (error) {
			console.log(error);
		}
	};

	const Buy = async (combo) => {
		// 先检测授权

		if (!isConnected.value) {
			open();
			return;
		}
		try {
			loading.value = true;
			let abi = [" function allowance(address owner, address spender) external view returns (uint256)"];
			let contract = new ethers.Contract(USDT.value, abi, await getprovider().getSigner());

			let allowance = await contract.allowance(address.value, SAlE.value);

			const Amount = combo == 1 ? ethers.parseUnits("200") : ethers.parseUnits("2000");
			if (allowance < Amount) {
				// 授权
				abi = ["function approve(address spender, uint256 amount) external returns (bool)"];
				contract = new ethers.Contract(USDT.value, abi, await getprovider().getSigner());

				let tx = await contract.approve(SAlE.value, Amount);
				await tx.wait();
			}
			abi = [" function register(address _referrer, uint8 _combo) external"];
			contract = new ethers.Contract(SAlE.value, abi, await getprovider().getSigner());
			// 这里地址还没处理
			let tx = await contract.register(useraddress.value, combo);
			await tx.wait();
			proxy.$message.success("Purchase successful");
			loading.value = false;
			getdata();
		} catch (error) {
			loading.value = false;
			if (error.info && error.info.error) {
				const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message;
				proxy.$message.error(errorMessage);
			} else {
				proxy.$message.error(error.message);
			}
			console.log(error.message);
		}
		// 交易
	};

	const claim = async () => {
		try {
			if (!isConnected.value) {
				open();
				return;
			}
			loadingclaim.value = true;
			let abi = ["function claim() public returns (bool)"];
			let contract = new ethers.Contract(pool.value, abi, await getprovider().getSigner());
			let allowance = await contract.claim();
			await allowance.wait();
			proxy.$message.success("Received successfully");
			loadingclaim.value = false;
			getdata();
		} catch (error) {
			loadingclaim.value = false;
			if (error.info && error.info.error) {
				const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message;
				proxy.$message.error(errorMessage);
			} else {
				proxy.$message.error(error.message);
			}
		}
	};

	const withdrawEarnings = async () => {
		try {
			if (!isConnected.value) {
				open();
				return;
			}
			loadingwithdrawEarnings.value = true;
			let abi = ["function withdrawEarnings() external"];
			let contract = new ethers.Contract(SAlE.value, abi, await getprovider().getSigner());
			let allowance = await contract.withdrawEarnings();
			await allowance.wait();
			proxy.$message.success("Received successfully");
			loadingwithdrawEarnings.value = false;
			getdata();
		} catch (error) {
			loadingwithdrawEarnings.value = false;
			if (error.info && error.info.error) {
				const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message;
				proxy.$message.error(errorMessage);
			} else {
				proxy.$message.error(error.message);
			}
		}
	};
	const redemption = async () => {
		try {
			if (!isConnected.value) {
				open();
				return;
			}
			loadingredemption.value = true;
			let abi = ["function redemption() external"];
			let contract = new ethers.Contract(pool.value, abi, await getprovider().getSigner());
			let allowance = await contract.redemption();
			await allowance.wait();
			proxy.$message.success("Redemption successful");
			loadingredemption.value = false;
			getdata();
		} catch (error) {
			loadingredemption.value = false;
			if (error.info && error.info.error) {
				const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message;
				proxy.$message.error(errorMessage);
			} else {
				proxy.$message.error(error.message);
			}
		}
	};
	const { toClipboard } = useClipboard();
	const Invitationlink = async () => {
		try {
			if (!address.value) {
				proxy.$message.error("Please link wallet first");
				return;
			}
			const currentUrl = window.location.href;
			let replacedUrl;
			if (route.params.address) {
				replacedUrl = currentUrl.replace(route.params.address, address.value);
			} else {
				replacedUrl = currentUrl + address.value;
			}

			await toClipboard(replacedUrl);
			proxy.$message.success("copy success");
			// navigator.clipboard
			// 	.writeText(replacedUrl)
			// 	.then(() => {
			// 		proxy.$message.success("copy success");
			// 	})
			// 	.catch((err) => {
			// 		proxy.$message.error("copy error");
			// 	});
		} catch (error) {
			proxy.$message.error("copy error");
			console.log(error);
		}
	};
	const isAnimating = ref(false);
	let interval = null;
	const randomAddress = ref("");
	const startBannerAnimation = () => {
		const randomTexts = ["Buy Fuel", "buy starship", "Claim Referral Rewards"];
		interval = setInterval(() => {
			isAnimating.value = !isAnimating.value;
			const wallet = ethers.Wallet.createRandom();
			const addressva = wallet.address;
			// 获取随机地址
			const randomTextIndex = Math.floor(Math.random() * randomTexts.length);

			randomAddress.value = addressva.slice(0, 8) + "..." + addressva.slice(-10) + " " + randomTexts[randomTextIndex];
		}, 1000);
	};
	const warning = ref("warning");
	const danger = ref("info");
	const info = ref("warning");
	const usdtvalue = ref(200);
	const buyvalue = ref(1);
	const buytext = ref("Buy fuel");
	const buttontoggle = (ele) => {
		if (ele == 1) {
			warning.value = "warning";
			danger.value = "info";
			info.value = "warning";
			usdtvalue.value = 200;
			buyvalue.value = 1;
			buytext.value = "Buy fuel";
			//左边按钮被点击
			// 设置自己的颜色
			// 设置右边按钮颜色
			// 设置购买按钮颜色
		} else {
			danger.value = "danger";
			warning.value = "info";

			info.value = "danger";
			usdtvalue.value = 2000;
			buyvalue.value = 5;
			buytext.value = "buy starship";
		}
	};

	watch(address, async (newChainId, oldChainId) => {
		await getjson();
		if (isConnected.value && !examine.value) {
			getdata();
		}
	});

	watch(isConnected, async (newChainId, oldChainId) => {
		await getjson();
		if (isConnected.value) {
			if (!examine.value) {
				getdata();
			}
		} else {
			participate.value = false;
			totalClaimable.value = 0;
			claimedTimestamps.value = 0;
			UserEarnings.value = 0;
		}
	});
	onMounted(async () => {
		startBannerAnimation();
		await getjson();
		fetchAllPurchases();
		getdata();
	});
	onBeforeUnmount(() => {
		clearInterval(interval); // 组件卸载前清除定时器
	});
</script>

<!-- 购买流程 -->

<!-- 检测授权   没授权的就授权   已经授权的就可以下单 -->
<style lang="scss">
	.strsmain {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 16px;
		color: aliceblue;
		gap: 16px;
		font-size: 14px;
		.banner {
			flex: 1;
			border-radius: 6px;
			color: black;
			background: rgb(49, 210, 242);
			font-size: 12px;
			padding: 5px 15px;
		}
		.banners {
			flex: 1;
			border-radius: 6px;
			background: rgb(147, 197, 253);
			font-size: 12px;
			padding: 5px 15px;
			color: black;
		}
		.left {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 16px;
			/* padding-right: 16px; */
			.el-button--success,
			.el-button--primary {
				height: 35px !important;
			}
			.admininfo {
				display: flex;
				flex-wrap: nowrap;
				gap: 10px;
				.adminimg {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 10px;
				}
			}
			.textinfo {
				display: flex;
				flex-direction: column;
				gap: 8px;
			}
			.Certification {
				background: rgb(43, 45, 56);
				border-radius: 10px;
				padding: 16px;
				.el-button--danger {
					height: 30px !important;
					font-size: 13px !important;
					border-radius: 6px !important;
				}
			}
			.invite {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			}
		}
		.rift {
			/* width: 33vw;
			padding-left: 40px; */
			display: flex;
			flex-direction: column;
			gap: 16px;
			.letftnoxbor {
				position: relative;
				border: 1px solid rgba(255, 255, 255, 0.15);
				.mask {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border-radius: 10px;
					background-color: rgba(0, 0, 0, 0.7); /* 半透明黑色蒙版 */
					z-index: 10; /* 确保蒙版在内容之上 */
				}
			}
			.letfbox {
				border-radius: 10px;
				background: rgb(43, 45, 56);
				padding: 16px;
				display: flex;
				flex-direction: column;
				gap: 16px;
				.el-tag--info {
					background-color: rgb(27, 29, 40);
					border: 1px solid rgb(27, 29, 40);
					color: rgb(153, 153, 153);
					/* width: auto; */
				}
				.tagtop {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
				}
				.tagbottom {
					margin-top: 16px;
				}
				.el-button--warning {
					background: #198754 !important;
					border-radius: 6px !important;
					border: 1px solid #198754 !important;
					height: 38px !important;
					font-size: 16px !important;
					color: rgb(255, 255, 255) !important;
				}
				.el-button--danger {
					background: rgb(27, 178, 218) !important;
					border-radius: 6px !important;
					border: 1px solid rgb(27, 178, 218) !important;
					height: 38px !important;
					font-size: 16px !important;
					color: rgb(255, 255, 255) !important;
				}
				.el-button--info {
					background: #6c757d !important;
					border-radius: 6px !important;
					border: 1px solid #6c757d !important;
					height: 38px !important;
					font-size: 16px !important;

					color: rgb(255, 255, 255) !important;
				}
				.el-input__wrapper:hover {
					box-shadow: 0 0 0 1px #c0c4cc inset !important;
				}
				.el-input-group__append {
					color: aliceblue !important;
					background: rgb(46, 48, 58) !important;
					box-shadow: 0 0 0 1px #c0c4cc inset !important;
				}
				.el-input__wrapper {
					box-shadow: 0 0 0 1px #c0c4cc inset !important;
				}
				.buttonlist {
					flex: 1;
					display: flex;
					flex-direction: row;
					.el-button {
						flex: 1;
					}
				}
			}
		}
		.bannersd {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}

		@media (min-width: 1000px) {
			.bannersd {
				display: flex;
				flex-direction: row;
				gap: 16px;
			}
		}

		@media (min-width: 980px) {
			flex-direction: row;

			.rift {
				width: 35vw;
				padding-left: 30px;
			}
		}
		@media (min-width: 1500px) {
			.rift {
				width: 495px;
				padding-left: 30px;
			}
		}
	}

	.headerAnimation {
		animation: shake 0.3s, colorChange 0.3s;
		animation-iteration-count: 6;
	}

	/* .headerPad1 {
		background-color: #6edff6;
		border-radius: 0.3rem;
		color: #000;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	} */

	/* 定义 shake 动画 */
	@keyframes shake {
		0% {
			transform: translate(0, 0);
		}
		25% {
			transform: translate(-5px, 0);
		}
		50% {
			transform: translate(5px, 0);
		}
		75% {
			transform: translate(-5px, 0);
		}
		100% {
			transform: translate(0, 0);
		}
	}

	/* 定义 colorChange 动画 */
	@keyframes colorChange {
		0% {
			background: rgba(137, 43, 226, 0.384); /* 第一个过渡颜色 */
		}
		25% {
			background-color: rgba(254, 111, 254, 0.6); /* 基准颜色 */
		}
		50% {
			background-color: rgba(255, 20, 147, 0.6); /* 深粉色 */
		}
		75% {
			background-color: rgba(255, 182, 193, 0.6); /* 浅粉色 */
		}
		100% {
			background-color: rgba(254, 111, 254, 0.438); /* 再次回到基准颜色 */
		}
	}

	/* 停顿效果通过关键帧中的过渡时间实现 */
</style>
<!-- 有两个节点  -->
<!-- 购买/已经购买界面 -->
<!-- 领取奖励 -->
<!-- 还缺 撤回LP -->
<!-- 领取推荐奖励 -->
