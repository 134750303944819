import { createRouter, createWebHistory,createWebHashHistory, RouteRecordRaw } from 'vue-router'
import IndexView from '../views/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:address?',
    name: 'home',
    component: IndexView,
    meta: { title: 'Starship' }
  },
]



const router = createRouter({
	history: createWebHashHistory(),
	routes,
})
router.beforeEach((to, from, next) => {
  const title = (to.meta.title as string) || 'Default Title';
  document.title = title;
  next();
});
export default router
