<template>
	<div element-loading-background="#6a4c4185" class="footer">
		<div class="main">
			<div class="logo">
				<el-avatar :size="30" src="/icons/logo.png"></el-avatar>
				<span class="appblack">Starship</span>
			</div>

			<!-- <el-image style="height: 50px"  fit="contain" /> -->
			<div class="left">
				<div class="chain" @click="isConnected ? open({ view: 'Account' }) : open({ view: 'Connect' })">
					<!-- 这个是哪个链 -->
					<div class="Connects"><el-image style="height: 24px" :src="isConnected ? '/icons/bsc.png' : '/icons/Icon.png'" fit="contain" /></div>
					<div class="chaintxt">
						<span>{{ isConnected ? "BNB Chain" : "Link wallet" }}</span>
					</div>
				</div>
				<div v-if="address" class="addresson" @click="open({ view: 'Account' })">{{ address.slice(0, 6) + "..." + address.slice(-4) }}</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { modal } from "@/api/web3ModalConfig";
	import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers/vue";
	import { useStore } from "vuex";
	import axios from "axios";
	import { watch, ref, onMounted, getCurrentInstance } from "vue";
	const { open, close } = useWeb3Modal();
	const { address, chainId, isConnected } = useWeb3ModalAccount();
	const { proxy } = getCurrentInstance();

	const store = useStore();
	const walletProvider = ref(56);

	const getjson = async () => {
		try {
			const response = await axios.get("/Setting.json");
			const config = response.data;

			walletProvider.value = config.switch ? 56 : 97;
		} catch (error) {
			console.log(error);
		}
	};

	watch(chainId, async (newVal, oldVal) => {
		await getjson();
		if (walletProvider.value != newVal) {
			open({ view: "Networks" });
		}
	});
	watch(address, (newVal, oldVal) => {});
</script>
<style scoped>
	@media (max-width: 700px) {
		.appblack {
			display: none;
		}
	}
	.addresson {
		border-radius: 4px;
		color: black;
		background: rgb(27, 178, 218);
		font-size: 13px;
		padding: 5px 10px;
	}
	.logo {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		gap: 5px;
	}
	.chain {
		display: flex;
		background: rgb(0, 0, 0);
		flex-wrap: nowrap;
		border-radius: 6px;
	}
	.chaintxt {
		font-size: 13px;
		text-transform: uppercase;
		font-weight: bold;
		padding: 5px 10px 5px 5px;
		display: flex;
		border-radius: 0 4px 4px 0;
		align-items: center;
	}

	.dropdown {
		position: relative;
		display: inline-block;
	}

	.dropdown-button {
		font-size: 0;
		background: rgb(43, 45, 56);
		border-radius: 4px;
		box-shadow: 0 0 0 1px #33344e inset !important;
		padding: 5px;
		border-radius: 4px;
		cursor: pointer;
	}
	.Connects {
		font-size: 0;

		padding: 5px 5px 5px 10px;
		width: 34px;
		border-radius: 4px 0 0 4px;
	}
	.Connect {
		font-size: 0;
		background: rgb(43, 45, 56);
		width: 34px;
		height: 34px;
		padding: 5px;
		border-radius: 4px;
		box-shadow: 0 0 0 1px #33344e inset;
	}
	.Connectis {
		box-shadow: 0 0 0 1px #3b82f6 inset;
	}

	.dropdown-content {
		display: none;
		position: absolute;
		z-index: 1;
		top: 100%;
		left: 0;
	}

	.dropdown:active .dropdown-content,
	.dropdown:hover .dropdown-content {
		display: block;
	}
	.langlist {
		margin-top: 5px;
		display: flex;
		flex-direction: column;
		box-shadow: 0 0 0 1px #33344e inset !important;
		background: rgb(43, 45, 56);
		border-radius: 4px;
		padding: 5px;
		gap: 8px;
	}

	.left {
		display: flex;
		flex-wrap: nowrap;

		align-items: center;
		gap: 10px;
	}
	.footer {
		display: flex;
		/* width: 100vw; */
		/* height: 73.6px; */
		top: 0;
		background-color: rgb(43, 45, 56);
		z-index: 99;
		align-items: center;
	}
	.footer .main {
		flex: 1;
		/* width: 100vw; */
		padding: 16px;
		color: aliceblue !important;
		/* margin: auto; */
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
	}
</style>
<style>
	@media (max-width: 500px) {
		:root {
			--wui-spacing-xs: 4px !important;
		}
	}
	button:disabled {
		color: #fff !important;
	}

	wui-flex > wui-text {
		color: aliceblue;
	}
	.el-loading-spinner .path {
		stroke: #6a4c41 !important;
	}
</style>
