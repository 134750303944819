// src/web3ModalConfig.ts
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/vue';

const projectId = '5f931bf56115df8e871c07ad48050535';

// 2. Set chains



const BNBChain = {
  chainId: 56,
  name: 'BNB Chain',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com/',
  rpcUrl: 'https://go.getblock.io/e493886b71c24f119d8579b0cfc19250',
}
const BNBTestnet = {
  chainId: 97,
  name: 'BNB Chain Testnet',
  currency: 'tBNB',
  explorerUrl: 'https://testnet.bscscan.com/',
  rpcUrl: 'https://go.getblock.io/e7ed2ba282b448c69d94df588c9cae9c',
}



// 3. Create your application's metadata object
const metadata = {
  name: 'Starship',
  description: 'Starship',
  url: 'https://xinjianuis.onrender.com/', // url must match your domain & subdomain
  icons: ['https://xinjianuis.onrender.com/favicon.ico'],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: false,
  defaultChainId: 97, // Coinbase SDK Default Chain ID
  auth: {
    email: false, // default to true
  }
});


const modal = createWeb3Modal({
  ethersConfig,
  tokens: {
    97: {
      address: '0xB2b7DaBC011C3E0b4471a36B00641E66E8b5F9e2'
    }
  },
  chains: [BNBChain, BNBTestnet],
  allowUnsupportedChain: false,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: false, // Optional - false as default
  featuredWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', //mask
    '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',//BIT
    'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b', //qur
    '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',//BIT
    '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66',//TP
  ],

  // includeWalletIds: [
  //   'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
  //   '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66',
  //   'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b',
  //   '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662'
  // ],
  // excludeWalletIds: [
  //   'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b',//PAICHU
  //   'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa'
  // ]

});





export { modal };
