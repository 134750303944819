


export default {
	message: {
		mainx:'星舰-火星计划财富之旅:',
		token:'代币名称:stas',
		totalamount:'发行总量:1000亿',
		trade:'交易费率:买卖2.5%',
		destroy:'销毁比例:买卖0.5%',
		Welfare:'星球福利:直推10%,间推5%',
		invite:'复制邀请链接',
		starship:'2000U[星舰]',
		dividend:'享受交易手续费5%分红',
		Delivery:'配送2000ULP',
		income:'游戏收益加权50%分红',
		ecology:'生态发展投票以及优先参与的权益',
		Exclusive:'星舰专属俱乐部',
		fuel:'200U[燃油]',
		Distributefuel:'配送200ULP',
		Incomecollection:'收益领取',
		Incomeavailable:'可领收益: ',
		Receivedincome:'已领收益: ',
		Receivebenefits:'领取收益',
		Invitationrewards:'邀请奖励',
		Rewardsavailable:'可领奖励:',
		Getreferralrewards:'领取推荐奖励',
		RedeemLP:'赎回LP',
		warn:'赎回会导致失去部分权益,谨慎操作',
		buystarship:'购买星舰',
		Buyfuel:'购买燃油',
		},

}







